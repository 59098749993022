import React, {Component} from "react"
import {connect} from "react-redux";
import styles from "./Item.module.css";
import {selectItem} from "./itemSlice";

enum STATUS {
    PRE,
    RENDER,
    POST
}

interface IProps {
    start: number,
    end: number,
    currentItem: number[],
    frame: number,
    preClass?: string,
    renderClass?: string,
    postClass?: string,
    baseClass?: string,
    itemClass?: string,
    children: any
}

interface IState {

}

class Item extends Component<IProps, IState> {
    status = STATUS.PRE

    calculateClasses(): string {
        if (this.props.currentItem[this.props.frame] < this.props.start)
            this.status = STATUS.PRE
        else if (this.props.currentItem[this.props.frame] >= this.props.end)
            this.status = STATUS.POST
        else
            this.status = STATUS.RENDER

        let tag = null;
        if (this.status === STATUS.PRE)
            tag = this.props.preClass || styles.pre
        else if (this.status === STATUS.RENDER)
            tag = this.props.renderClass || styles.render
        else if (this.status === STATUS.POST)
            tag = this.props.postClass || styles.post
        else
            return this.props.itemClass || styles.item
        let classes = `${styles.item} ${tag}`
        if (this.props.baseClass)
            classes += ` ${this.props.baseClass}`
        return classes
    }

    render() {
        return <div className={this.calculateClasses()}>{this.props.children}</div>
    }
}

export default connect((state: any) => ({currentItem: selectItem(state)}))(Item);