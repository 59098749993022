import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import frameReducer from '../features/frame/frameSlice';
import controlsReducer from "../features/controls/controlSlice";
import itemReducer from "../features/item/itemSlice"

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        frame: frameReducer,
        controls: controlsReducer,
        item: itemReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
