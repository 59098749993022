import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";


export interface ItemState {
    value: number[]
}

const initialState: ItemState = {
    value: [-1]
}

export const itemSlice = createSlice({
    name: "item",
    initialState,
    reducers: {
        increment: (state, action: PayloadAction<number>) => {
            const index = action.payload
            while (state.value.length <= index)
                state.value.push(-1)
            state.value[index]++;
        },
        decrement: (state, action: PayloadAction<number>) => {
            const index = action.payload
            if (state.value.length <= index)
                return
            state.value[index]--;
        },
        reset: (state, action: PayloadAction<number>) => {
            const index = action.payload
            while (state.value.length <= index)
                state.value.push(-1)
            state.value[index] = -1
        },
        set: (state, action: PayloadAction<{ index: number, value: number }>) => {
            const {index, value} = action.payload
            while (state.value.length <= index)
                state.value.push(-1)
            state.value[index] = value
        }
    }
})

export const {increment, decrement, reset, set} = itemSlice.actions

export const selectItem = (state: RootState) => {
    return state.item.value
}

export default itemSlice.reducer
