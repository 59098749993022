import React, {Component} from "react"
import {connect} from "react-redux";
import Frame from "../../features/frame/Frame";
import {selectFrameNumber} from "../../features/frame/frameSlice";
import {increment, reset} from "../../features/item/itemSlice";
import Item from "../../features/item/Item";
import {display, setBackwardActive, setForwardActive} from "../../features/controls/controlSlice";
import styles from "./Frame8.module.css"
import redtailedhawk from "./redtailedhawk.jpg"
import chickadee from "./chickadee.png"

interface IProps {
    currentFrame: number,
    increment: () => any,
    reset: () => any,
    display: (on: boolean) => any,
    setForwardActive: (enabled: boolean) => any,
    setBackwardActive: (enabled: boolean) => any,
}

interface IState {

}

class Frame8 extends Component<IProps, IState> {
    frame = 8
    firstRun = true
    index = 0
    maxIndex = 7
    timings = [1000, 1000, 1500, 1500, 1500, 1500, 500, 500]

    constructor(props: IProps) {
        super(props)
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        if (this.props.currentFrame !== nextProps.currentFrame && nextProps.currentFrame === this.frame) {
            this.props.reset()
        }
        return true
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.currentFrame !== prevProps.currentFrame && this.props.currentFrame === this.frame)
            this.load()
    }

    step() {
        if(this.index == this.maxIndex) {
            this.firstRun = false
            this.props.display(true)
            this.props.setForwardActive(true)
            this.props.setBackwardActive(false)
            return
        }
        const timing = this.timings[this.index]
        this.index++;

        setTimeout(() => {
            this.props.increment()
            this.step()
        }, timing)
    }

    stepConstant(time: number) {
        if(this.index == this.maxIndex)
            return

        this.index++

        setTimeout(() => {
            this.props.increment()
            this.stepConstant(time)
        }, time)
    }

    load() {
        if (this.firstRun) {
            this.props.display(false)
            this.props.setBackwardActive(false);
            this.props.setForwardActive(false);

            this.index = 0
            this.step()
        } else {
            this.index = 0
            this.stepConstant(500)
        }
    }

    render() {
        return <Frame frame={this.frame} baseClass={styles.base}>
            <Item frame={this.frame} start={0} end={this.maxIndex} baseClass={styles.text1}><h1 className={styles.heavy}>Feb 4</h1>
            </Item>
            <Item frame={this.frame} start={1} end={this.maxIndex} baseClass={styles.text3}><h2 className={styles.normal}>{"We interrupt this broadcast to bring you a scheduled interruption"}</h2></Item>
            <Item frame={this.frame} start={2} end={this.maxIndex} baseClass={styles.text4}><h2 className={styles.normal}>{"..."}</h2></Item>
            <Item frame={this.frame} start={3} end={this.maxIndex} baseClass={styles.text5}><h2 className={styles.normal}>{"For birds."}</h2></Item>
            <Item frame={this.frame} start={4} end={this.maxIndex} baseClass={styles.text6}><h2 className={styles.normal}>{"Enjoy."}</h2></Item>
            <Item frame={this.frame} start={5} end={this.maxIndex} baseClass={styles.image1}><img className={styles.image} src={redtailedhawk}
                                                                                                  alt={"red tailed hawk"}/>
            </Item>
            <Item frame={this.frame} start={6} end={this.maxIndex} baseClass={styles.image2}><img className={styles.image} src={chickadee}
                                                                                                  alt={"chickadee"}/>
            </Item>
        </Frame>
    }
}

export default connect((state: any) => ({currentFrame: selectFrameNumber(state)}), (dispatch) => ({
    increment: () => dispatch(increment(8)),
    reset: () => dispatch(reset(8)),
    display: (on: boolean) => dispatch(display(on)),
    setBackwardActive: (enabled: boolean) => dispatch(setBackwardActive(enabled)),
    setForwardActive: (enabled: boolean) => dispatch(setForwardActive(enabled)),
}))(Frame8)