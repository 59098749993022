import React, {Component} from "react"
import {connect} from "react-redux";
import Frame from "../../features/frame/Frame";
import {selectFrameNumber} from "../../features/frame/frameSlice";
import {increment, reset} from "../../features/item/itemSlice";
import Item from "../../features/item/Item";
import {display, setBackwardActive, setForwardActive} from "../../features/controls/controlSlice";
import styles from "./Frame2.module.css"

interface IProps {
    currentFrame: number,
    increment: () => any,
    reset: () => any,
    display: (on: boolean) => any,
    setForwardActive: (enabled: boolean) => any,
    setBackwardActive: (enabled: boolean) => any,
}

interface IState {

}

class Frame2 extends Component<IProps, IState> {
    frame = 2
    firstRun = true

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        if (this.props.currentFrame !== nextProps.currentFrame && nextProps.currentFrame === this.frame) {
            this.props.reset()
        }
        return true
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.currentFrame !== prevProps.currentFrame && this.props.currentFrame === this.frame)
            this.load()
    }

    load() {
        if (this.firstRun) {
            this.props.display(false)
            this.props.setBackwardActive(false);
            this.props.setForwardActive(false);
            setTimeout(() => {
                this.props.increment()
                setTimeout(() => {
                    this.props.increment()
                    setTimeout(() => {
                        this.props.increment()
                        setTimeout(() => {
                            this.props.increment()
                            setTimeout(() => {
                                this.props.increment()
                                this.firstRun = false
                                this.props.display(true)
                                this.props.setForwardActive(true)
                                this.props.setBackwardActive(false)
                            }, 3000)
                        }, 3000)
                    }, 3000)
                }, 2000)
            }, 1000)
        } else {
            setTimeout(() => {
                this.props.increment()
                setTimeout(() => {
                    this.props.increment()
                    setTimeout(() => {
                        this.props.increment()
                        setTimeout(() => {
                            this.props.increment()
                            setTimeout(() => {
                                this.props.increment()
                            }, 500)
                        }, 500)
                    }, 500)
                }, 500)
            }, 500)
        }
    }

    render() {
        return <Frame frame={this.frame} baseClass={styles.base}>
            <Item frame={this.frame} start={0} end={5} baseClass={styles.text1}><h2 id={styles.text3id}>It's funny</h2>
            </Item>
            <Item frame={this.frame} start={1} end={5} baseClass={styles.text2}><h2
                id={styles.text3id}>We <i>technically</i> started on Feburary 21st</h2></Item>
            <Item frame={this.frame} start={2} end={5} baseClass={styles.text3}><h2 id={styles.text3id}>Making today
                our <b>one month anniversary</b></h2></Item>
            <Item frame={this.frame} start={3} end={5} baseClass={styles.text4}><h2 id={styles.text3id}>But it's
                been <b>over two</b> since it really started</h2></Item>
            <Item frame={this.frame} start={4} end={5} baseClass={styles.text5}><h2 id={styles.text3id}>So here's us, starting <b>January
                16th...</b></h2></Item>

        </Frame>
    }
}

export default connect((state: any) => ({currentFrame: selectFrameNumber(state)}), (dispatch) => ({
    increment: () => dispatch(increment(2)),
    reset: () => dispatch(reset(2)),
    display: (on: boolean) => dispatch(display(on)),
    setBackwardActive: (enabled: boolean) => dispatch(setBackwardActive(enabled)),
    setForwardActive: (enabled: boolean) => dispatch(setForwardActive(enabled)),
}))(Frame2)