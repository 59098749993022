import React, {Component} from "react"
import {connect} from "react-redux";
import Frame from "../../features/frame/Frame";
import {selectFrameNumber} from "../../features/frame/frameSlice";
import {increment, reset} from "../../features/item/itemSlice";
import Item from "../../features/item/Item";
import {display, setBackwardActive, setForwardActive} from "../../features/controls/controlSlice";
import styles from "./Frame4.module.css"
import group from "./group.png"
import boba_bear from "./boba_bear.jpg"
import pokemon from "./pokemon.jpeg"
import hearts from "./hearts.jpeg"
import pretty from "./pretty.jpeg"
import group2 from "./group2.png"

interface IProps {
    currentFrame: number,
    increment: () => any,
    reset: () => any,
    display: (on: boolean) => any,
    setForwardActive: (enabled: boolean) => any,
    setBackwardActive: (enabled: boolean) => any,
}

interface IState {

}

class Frame4 extends Component<IProps, IState> {
    frame = 4
    firstRun = true
    index = 0
    maxIndex = 13
    timings = [1000, 2000, 500, 500, 500, 3000, 0, 3000, 0, 3000, 0, 3000, 2000]

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        if (this.props.currentFrame !== nextProps.currentFrame && nextProps.currentFrame === this.frame) {
            this.props.reset()
        }
        return true
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.currentFrame !== prevProps.currentFrame && this.props.currentFrame === this.frame)
            this.load()
    }

    step() {
        if(this.index === this.maxIndex) {
            this.firstRun = false
            this.props.display(true)
            this.props.setForwardActive(true)
            this.props.setBackwardActive(false)
            return
        }
        const timing = this.timings[this.index]
        this.index++;

        setTimeout(() => {
            this.props.increment()
            this.step()
        }, timing)
    }

    stepConstant(time: number) {
        if(this.index === this.maxIndex)
            return

        this.index++

        setTimeout(() => {
            this.props.increment()
            this.stepConstant(time)
        }, time)
    }

    load() {
        if (this.firstRun) {
            this.props.display(false)
            this.props.setBackwardActive(false);
            this.props.setForwardActive(false);

            this.index = 0
            this.step()
        } else {
            this.index = 0
            this.stepConstant(500)
        }
    }

    render() {
        return <Frame frame={this.frame} baseClass={styles.base}>
            <Item frame={this.frame} start={0} end={this.maxIndex} baseClass={styles.text1}><h1 className={styles.heavy}>Jan 17</h1>
            </Item>
            <Item frame={this.frame} start={1} end={this.maxIndex} baseClass={styles.text2}><h2 className={styles.thin}>Boston</h2>
            </Item>
            <Item frame={this.frame} start={2} end={this.maxIndex} baseClass={styles.image1}><img className={styles.image} src={group}
                                                                                      alt={"group"}/>
            </Item>
            <Item frame={this.frame} start={3} end={this.maxIndex} baseClass={styles.image2}><img className={styles.image} src={boba_bear}
                                                                                      alt={"heneni as a boba bear"}/>
            </Item>
            <Item frame={this.frame} start={4} end={this.maxIndex} baseClass={styles.image3}><img className={styles.image} src={group2}
                                                                                      alt={"group"}/>
            </Item>
            <Item frame={this.frame} start={5} end={this.maxIndex} baseClass={styles.text3}><h2 className={styles.normal}>Things...
                really started changing today</h2></Item>
            <Item frame={this.frame} start={6} end={this.maxIndex} baseClass={styles.image4}><img className={styles.image} src={pokemon}
                                                                                      alt={"arthur in a pokemon hat"}/>
            </Item>
            <Item frame={this.frame} start={7} end={this.maxIndex} baseClass={styles.text4}><h2 className={styles.normal}>Hand
                holding</h2></Item>
            <Item frame={this.frame} start={8} end={this.maxIndex} baseClass={styles.image5}><img className={styles.image} src={pretty}
                                                                                      alt={"heneni is pretty"}/>
            </Item>
            <Item frame={this.frame} start={9} end={this.maxIndex} baseClass={styles.text5}><h2 className={styles.normal}>Things
                went from platonic to romantic</h2></Item>
            <Item frame={this.frame} start={10} end={this.maxIndex} baseClass={styles.image6}><img className={styles.image} src={hearts}
                                                                                      alt={"i love you"}/>
            </Item>
            <Item frame={this.frame} start={11} end={this.maxIndex} baseClass={styles.text6}><h2 className={styles.normal}>We could
                say this is when we really started</h2></Item>
        </Frame>
    }
}

export default connect((state: any) => ({currentFrame: selectFrameNumber(state)}), (dispatch) => ({
    increment: () => dispatch(increment(4)),
    reset: () => dispatch(reset(4)),
    display: (on: boolean) => dispatch(display(on)),
    setBackwardActive: (enabled: boolean) => dispatch(setBackwardActive(enabled)),
    setForwardActive: (enabled: boolean) => dispatch(setForwardActive(enabled)),
}))(Frame4)