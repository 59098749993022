import React from 'react';
import './App.css';
import Controls from "./features/controls/Controls";
import Frame0 from "./components/frame0/Frame0";
import Frame1 from "./components/frame1/Frame1";
import Frame2 from "./components/frame2/Frame2";
import Frame3 from "./components/frame3/Frame3";
import Frame4 from "./components/frame4/Frame4";
import Frame5 from "./components/frame5/Frame5";
import Frame6 from "./components/frame6/Frame6";
import Frame7 from "./components/frame7/Frame7";
import Frame8 from "./components/frame8/Frame8";
import Frame9 from "./components/frame9/Frame9";
import Frame10 from "./components/frame10/Frame10";
import Frame11 from "./components/frame11/Frame11";
import Frame12 from "./components/frame12/Frame12";
import Frame13 from "./components/frame13/Frame13";
import Frame14 from "./components/frame14/Frame14";
import Frame15 from "./components/frame15/Frame15";
import Frame16 from "./components/frame16/Frame16";

function App() {
    return (
        <div className="App">
            <Controls/>
            <Frame0/>
            <Frame1/>
            <Frame2/>
            <Frame3/>
            <Frame4/>
            <Frame5/>
            <Frame6/>
            <Frame7/>
            <Frame8/>
            <Frame9/>
            <Frame10/>
            <Frame11/>
            <Frame12/>
            <Frame13/>
            <Frame14/>
            <Frame15/>
            <Frame16/>
        </div>
    );
}

export default App;
