import React, {Component} from "react"
import {connect} from "react-redux";
import {selectBack, selectDisplay, selectForward} from "./controlSlice";
import styles from "./controls.module.css"
import {nextFrame, previousFrame} from "../frame/frameSlice";

import left_arrow from "./left-arrow-24px.svg"
import right_arrow from "./right-arrow-24px.svg"

interface IProps {
    display: boolean,
    back: boolean,
    forward: boolean,
    nextFrame: () => any,
    previousFrame: () => any,
}

interface IState {

}

class Controls extends Component<IProps, IState> {

    render() {
        return <div className={[styles.container, this.props.display ? styles.visible : undefined].join(" ")}>
            <div className={styles.back}>
                <button onClick={this.props.previousFrame} disabled={!this.props.back}><img src={left_arrow} alt={"back"}/></button>
            </div>
            <div className={styles.forward}>
                <button onClick={this.props.nextFrame} disabled={!this.props.forward}><img src={right_arrow} alt={"forward"}/></button>
            </div>
        </div>
    }
}

export default connect((state: any) => ({
    display: selectDisplay(state),
    back: selectBack(state),
    forward: selectForward(state)
}), (dispatch) => ({
    nextFrame: () => dispatch(nextFrame()),
    previousFrame: () => dispatch(previousFrame())
}))(Controls)