import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";


export interface ControlsState {
    display: boolean,
    backActive: boolean,
    forwardActive: boolean
}

const initialState: ControlsState = {
    display: false,
    backActive: true,
    forwardActive: true
}

export const controlsSlice = createSlice({
    name: "controls",
    initialState,
    reducers: {
        activateAll: state => {
            state.display = true
            state.forwardActive = true
            state.backActive = true
        },
        display: (state, action: PayloadAction<boolean>) => {
            state.display = action.payload
        },
        setForwardActive: (state, action: PayloadAction<boolean>) => {
            state.forwardActive = action.payload
        },
        setBackwardActive: (state, action: PayloadAction<boolean>) => {
            state.backActive = action.payload
        }
    }
})

export const {activateAll, display, setForwardActive, setBackwardActive} = controlsSlice.actions

export const selectDisplay = (state: RootState) => state.controls.display
export const selectBack = (state: RootState) => state.controls.backActive
export const selectForward = (state: RootState) => state.controls.forwardActive

export default controlsSlice.reducer