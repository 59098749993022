import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";


export interface FrameState {
    value: number;
}

const initialState: FrameState = {
    value: 0
}

export const frameSlice = createSlice({
    name: 'frame',
    initialState,
    reducers: {
        nextFrame: state => {
            state.value += 1;
        },
        previousFrame: state => {
            state.value -= 1;
        }
    }
})

export const {nextFrame, previousFrame} = frameSlice.actions

export const selectFrameNumber = (state: RootState) => state.frame.value;

export default frameSlice.reducer