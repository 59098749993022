import React, {Component} from "react"

import styles from "./Frame.module.css";
import {selectFrameNumber} from "./frameSlice";
import {connect} from "react-redux";

enum STATUS {
    PRE,
    RENDER,
    POST,
}

interface IState {

}

interface IProps {
    children: any,
    preClass?: string,
    renderClass?: string,
    postClass?: string,
    frameClass?: string,
    baseClass?: string,
    frame: number,
    currentFrame: number
}

class Frame extends Component<IProps, IState> {
    status = STATUS.PRE

    calculateClasses(): string {
        if (this.props.currentFrame < this.props.frame)
            this.status = STATUS.PRE
        else if (this.props.currentFrame === this.props.frame)
            this.status = STATUS.RENDER
        else if (this.props.currentFrame > this.props.frame)
            this.status = STATUS.POST
        else
            this.status = STATUS.PRE

        let tag = null;
        if (this.status === STATUS.PRE)
            tag = this.props.preClass || styles.pre
        else if (this.status === STATUS.RENDER)
            tag = this.props.renderClass || styles.render
        else if (this.status === STATUS.POST)
            tag = this.props.postClass || styles.post
        else
            return this.props.frameClass || styles.frame
        let classes = `${styles.frame} ${tag}`
        if (this.props.baseClass)
            classes += ` ${this.props.baseClass}`
        return classes
    }

    render() {
        return <div className={this.calculateClasses()}>{this.props.children}</div>
    }
}

export default connect((state: any) => ({currentFrame: selectFrameNumber(state)}))(Frame);